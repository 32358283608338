.agreement{
    height: 100%;
    width: 100%;
    margin: 50px 90px 12px 90px;
    text-align: start;
    line-height: 25px;
    font-size: 16px;
}

li {
    font-size: 14px;
    margin: 5px 0 5px 0;
}
@media only screen and (max-device-width: 430px) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}
@media only screen
    and (width: 393px)
    and (height: 852px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 430px)
    and (height: 932px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 390px)
    and (height: 844px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 428px)
    and (height: 926px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 375px)
    and (height: 812px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 414px)
    and (height: 896px)
    and (-webkit-device-pixel-ratio: 3) {
    .agreement{
        margin: 50px 20px 50px 20px;
    }
}

@media only screen
    and (width: 375px)
    and (height: 667px)
    and (-webkit-device-pixel-ratio: 2) {

    .agreement{
        margin: 50px 20px 50px 20px;
    }
}