.dr-header {
    text-align: center;
}

.company-name {
    font-size: 38px;
    text-align: center;
    font-family: "Roboto Regular", serif;
    transform: translate(169,43);
    margin-bottom: 0px;
}

.contact-info {
    font-size: 11px;
    max-width: 278px;
    height: 68%;
    margin: auto;
    text-align: center;
    transform: translate(139 14);
    font-family: "Roboto Regular", serif;
}

@media only screen and (max-width: 280px) {
    .company-name {
        font-size: 18px;
    }
}

@media only screen and (min-width: 281px) {
    .company-name {
        font-size: 30px;
    }
}