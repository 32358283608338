@font-face {
    font-family: "Roboto Regular";
    src: url('font/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Vollkorn";
    src: url('font/Vollkorn-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

.navbar {
    margin: 5px 0 5px 0;
    padding: 5px 0 5px 0;
}

.content {
    min-height: 75vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
}

@media only screen and (max-width: 280px) {
    .navbar-navlink {
        margin-bottom: 0rem;
        font-size: 12px;
    }

    .content{
        min-height: 50vh;
    }
}

@media only screen and (min-width: 281px) {
    .navbar-navlink {
        margin-bottom: 0rem;
        font-size: 12px;
    }

    .content {
        min-height: 75vh;
    }
}


@media only screen and (min-width: 390px) {
    .navbar-navlink {
        margin-bottom: 0rem;
    }

    .content {
        min-height: 73vh;
    }
}

@media only screen and (min-width: 480px) {
    .navbar-navlink {
        margin-bottom: 0rem;
    }
}

@media only screen and (min-width: 768px) {
    .navbar-navlink {
        font-size: 18px;
    }

    .content{
        min-height: 75vh;
    }
}

@media only screen and (min-width: 992px) {
    .navbar-navlink {
        font-size: 18px;

    }
    .content{
        min-height: 75vh;
    }
}
