@media only screen and (max-width: 280px) {
    .db-label {
        width: 120px;
        height: 120px;
    }
}

@media only screen and (min-width: 281px) {
    .db-label {
        width: 150px;
        height: 150px;
    }
}

@media only screen and (min-width: 480px) {
    .db-label {
        width: 150px;
        height: 150px;
    }
}

@media only screen and (min-width: 768px) {
    .db-label {
        width: 150px;
        height: 150px;
    }
}