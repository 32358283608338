.legal-information-container {
    height: 100%;
}

/*.content{*/
/*    height: 75vh;*/
/*}*/

.legal-information-title {
    text-align: center;
    font-size: 40px;
    font-family: "Roboto Regular", serif;
    transform: translate(169, 43);
}

.title {
    font-size: 38px;
}

.legal-information-text-container {
    text-align: center;
    font-size: 18px;
    font-family: "Roboto Regular", serif;
    margin: 20px 100px 0 100px;
}


@media only screen and (max-width: 280px) {
    .legal-information-text-container {
        font-size: 12px;
        margin: 15px 20px 0 20px;
        text-align: start;
    }

    .title {
        font-size: 23px;
    }
}

@media only screen and (min-width: 281px) {
    .legal-information-text-container {
        font-size: 12px;
        margin: 15px 20px 0 20px;
        text-align: start;
    }

    .title {
        font-size: 23px;
    }
}

@media only screen and (min-width: 412px) {
    .legal-information-text-container {
        font-size: 12px;
        margin: 15px 20px 0 20px;
        text-align: start;
    }

    .title {
        font-size: 30px;
    }
}

@media only screen and (min-width: 480px) {
    .legal-information-text-container {
        font-size: 12px;
        margin: 15px 20px 0 20px;
        text-align: start;
    }

    .title {
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .legal-information-text-container {
        font-size: 25px;
        margin: 20px 30px 0 30px;
        text-align: center;
    }

    .title {
        font-size: 38px;
    }
}

@media only screen and (min-width: 1024px) {
    .legal-information-text-container {
        text-align: center;
        font-size: 18px;
        font-family: "Roboto Regular", serif;
        margin: 20px 100px 0 100px;
    }

    .title {
        font-size: 38px;
    }
}

@media only screen and (min-width: 1200px) {
    .legal-information-text-container {
        text-align: center;
        font-size: 18px;
        font-family: "Roboto Regular", serif;
        margin: 20px 100px 0 100px;
    }

    .title {
        font-size: 38px;
    }
}

