.dkbContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productsContainer {
    background-color: black;
}

.intro {
    max-width: 800px;
    margin-bottom:  10px;
}

.links {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 1.5rem;
   margin: 2rem 0 2rem 0;
}

.badgeStore {
    max-width: 200px;
}

.qr {
    max-width: 150px;
    max-height: 150px;
}

.banner{
   background-color: black;
   padding-top: 5rem;
   padding-left: 5rem;
}

.banner-header {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: -10rem;
}

.up {
    display: contents;
}

.down {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 3rem;
    justify-content: flex-start;
}

.bh-img {
    width: 100%;
}

.star {
    width: 40%;
    margin-top: -5rem;
}

@media only screen and (max-width: 280px) {
    .banner{
       padding-top: 3rem;
       padding-left: 3rem;
    }

    .down {
        gap: 2rem;
    }

    .star {
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 281px) {
    .banner{
       padding-top: 2.5rem;
       padding-left: 2.5rem;
    }

    .down {
        gap: 2rem;
    }

    .star {
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 360px) {
    .banner{
       padding-top: 3rem;
       padding-left: 3rem;
    }

    .down {
        gap: 2rem;
    }

    .star {
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 390px) {
    .banner{
       padding-top: 3rem;
       padding-left: 3rem;
    }

    .down {
        gap: 2rem;
    }

    .star {
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 480px) {
    .banner{
       padding-top: 3rem;
       padding-left: 3rem;
    }

    .down {
        gap: 2rem;
    }

    .star {
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 768px) {
    .banner{
       padding-top: 5rem;
       padding-left: 5rem;
    }

    .down {
        gap: 3rem;
    }

    .star {
        margin-top: -5rem;
    }
}

