.dr-footer {
    display: block;
    font-size: 12px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 5.0rem;
}

.footer-link {
    color: unset;
    text-decoration: none;
}

.footer-text-margin {
    margin: 0 0 0 0;
    font-family: "Roboto Regular", serif;
}